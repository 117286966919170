<template>
  <v-container>
    <v-btn v-on:click="login()">Login</v-btn>
    <v-btn v-on:click="getPackages()">Packages</v-btn>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "NavigraphTest",
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser'
    }),
  },
  methods: {
    login: function(){
      fetch(this.$baseURL + "/generateNavigraphAuthorizationURI")
        .then(response => response.json())
        .then(function (data){
          console.log(data['result'])
          window.open(data['result'])
      }.bind(this))
    },
    getPackages: function(){
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + this.currentUser['tokenHash']
        },
      }

      fetch(this.$baseURL + "/" + this.currentUser['user'] + "/navigraphGetPackages", requestOptions)
        .then(response => response.json())
        .then(function(data){
          console.log(data)
        }.bind(this))
    }
  }
}
</script>

<style scoped>

</style>
